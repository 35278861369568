<template>
  <div>
    <c-table
      ref="table"
      title="현장조치사항 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="workPermit.onSiteMeasures"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable"
      selection="multiple"
      rowKey="sopOnSiteMeasureId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addInfo" />
          <c-btn v-if="editable" label="제외" icon="remove" :showLoading="false" @btnClicked="remove" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'currentPicture'">
          <c-upload-picture
            class="swpOnSiteMeasurePicture"
            height="250px"
            :editable="editable"
            v-bind:attachInfo="{
              isSubmit: '',
              taskClassCd: 'WORK_PERMIT_ON_SITE_CURRENT',
              taskKey: props.row.sopOnSiteMeasureId,
            }">
          </c-upload-picture>
        </template>
        <template v-else-if="col.name === 'actionPicture'">
          <c-upload-picture
            class="swpOnSiteMeasurePicture"
            height="250px"
            :editable="editable"
            v-bind:attachInfo="{
              isSubmit: '',
              taskClassCd: 'WORK_PERMIT_ON_SITE_ACTION',
              taskKey: props.row.sopOnSiteMeasureId,
            }">
          </c-upload-picture>
        </template>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'swpOnSiteMeasure',
  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
          onSiteMeasures: [],
          deleteOnSiteMeasures: [],
        }
      }
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'currentPicture',
            field: 'currentPicture',
            label: '현장사진',
            align: 'center',
            sortable: false,
            style: 'width:25%',
            type: 'custom',
          },
          {
            name: 'currentSituation',
            field: 'currentSituation',
            label: '현재상황',
            align: 'center',
            sortable: false,
            style: 'width:20%',
            type: 'textarea',
          },
          {
            name: 'actionPicture',
            field: 'actionPicture',
            label: '조치후사진',
            align: 'center',
            sortable: false,
            style: 'width:25%',
            type: 'custom',
          },
          {
            name: 'actionSituation',
            field: 'actionSituation',
            label: '조치상황',
            align: 'center',
            sortable: false,
            style: 'width:20%',
            type: 'textarea',
          },
          {
            name: 'actionFlag',
            field: 'actionFlag',
            label: '조치여부',
            align: 'center',
            sortable: false,
            style: 'width:10%',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
        ],
        data: [],
        height: '350px'
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    height() {
      this.setTabHeight();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.setTabHeight();
    },
    addInfo() {
      this.workPermit.onSiteMeasures.splice(0, 0, {
        sopOnSiteMeasureId: uid(),  // 안전작업허가서 현장조치 일련번호
        sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
        currentSituation: '',  // 현재상황
        actionSituation: '',  // 조치상황
        actionFlag: 'N',  // 조치여부
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      })
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '제외할 행을 지정하세요.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.workPermit.deleteOnSiteMeasures) {
            this.workPermit.deleteOnSiteMeasures = [];
          }
          if (this.$_.findIndex(this.workPermit.deleteOnSiteMeasures, { sopWorkerId: item.sopWorkerId }) === -1) {
            this.workPermit.deleteOnSiteMeasures.push(item)
          }
          this.workPermit.onSiteMeasures = this.$_.reject(this.workPermit.onSiteMeasures, item);
        });
      }
    },
    setTabHeight() {
      let returnText = '500px';
      returnText = this.height && !isNaN(Number(this.height.replace('px', ''))) ? String(Number(this.height.replace('px', '')) - 70) + 'px' : returnText;
      this.grid.height = returnText;
    },
  }
};
</script>
<style lang="sass">
.swpOnSiteMeasurePicture
  margin: 0 !important
  padding: 0 !important
  .q-uploader__dnd
    display: none
</style>